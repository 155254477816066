@import "./icons.scss";
@import "./tailwind.scss";
@import "../../../node_modules/prismjs/themes/prism-okaidia.css"; 
@import "../../../node_modules/lightbox.js-react/dist/index.css"; 

@font-face {
    font-family: "PublicSans";
   // src: url("../fonts/PublicSans.ttf") format("truetype");
   src: url("../fonts/PublicSans.ttf") format("truetype");
   src: url("../fonts/PublicSans.eot") format("truetype");
   src: url("../fonts/PublicSans.woff") format("truetype");
  }
