input {
  font-family: Vazirmatn, sans-serif;
  text-align: center;
  width: 280px;
  outline: 0;
  border-radius: 8px;
  height: 45px;
  border: 1px solid #dedede;
}
.bigblue {
  z-index: 1051 !important; /* Ensure this is higher than the modal's z-index */
  margin-top: 20%;
}
@media only screen and (max-width: 600px) {
  .bigblue {
    z-index: 1051 !important; /* Ensure this is higher than the modal's z-index */
    margin-top: 20%;
  }
}
