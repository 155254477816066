input {
  font-family: Vazirmatn, sans-serif;
  text-align: center;
  width: 280px;
  outline: 0;
  border-radius: 8px;
  height: 45px;
  border: 1px solid #dedede;
}
.bigblue {
  z-index: 1051 !important; /* Ensure this is higher than the modal's z-index */
  margin-top: 20%;
}
.rmdpinput {
 
  height: 80px !important;
 
}


.projectgallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}

.projectitem {
  position: relative;
  margin-bottom: 20px;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.projectimage {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.project-item:hover .project-image {
  transform: scale(1.1);
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.project-item:hover .delete-button {
  opacity: 1;
}





@media only screen and (max-width: 600px) {
  .bigblue {
    z-index: 1051 !important; /* Ensure this is higher than the modal's z-index */
    margin-top: 20%;
  }
}
